<template>
  <b-card class="shop-agents">
    <div class="row mb-1">
      <div v-for="(headerLabel, hk) in headerLabels" class="col-12 col-md-3" :key="`hk_${hk}`">
        <HeaderCard 
          :counter="headerLabel.count"
          :img="headerLabel.img"
          :label="headerLabel.label"
        />
      </div>
    </div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group :label="$t('From Date')">
          <b-form-datepicker
            v-model="dataInfo.fromDate"
            locale="en"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group :label="$t('To Date')">
          <b-form-datepicker
            v-model="dataInfo.toDate"
            locale="en"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mt-auto mb-1"
      >
        <b-button
          variant="primary"
          @click="loadAgentDashboard"
        >
          {{ $t('Search') }}
        </b-button>
      </b-col>
      <b-col cols="12">
        <good-data-table
          ref="agentsGrid"
          :columns="agentsColumns"
          :total-column="'response.responseData.total'"
          :data-column="`Object.values(response.responseData.data).filter(x => typeof x === 'object')`"
          :api-endpoint="'/shop/agents/data/statistics'"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showAgentSelectionModal"
      :no-close-on-backdrop="true"
      :title="$t('Choose Orders')"
      size="xl"
      hide-footer
      @hidden="showAgentSelectionModal = false"
    >
      <div class="row">
        <div class="col-12">
          <b-card>
            <good-data-table
                ref="collectCod"
                :mode="'local'"
                :no-serial="true"
                :columns="collectCodColumns"
                :load-local-data="true"
                :chBoxenabled="true"
                :chkBoxAction="{active:true, deactive: false, activeLabel: 'Collect Amount', selectionText: `orders selected,  Total Amount: ${collectCodAmount}`}"
                :selectionInfoClass="'selection-info'"
                @enable="enableDisableSelected"
                @onItemSelected="onItemSelected"
              />
          </b-card>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import moment from 'moment'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from './useStore'
import { isEmpty, dateTimeFormat } from '@/utilities'
import { mapActions } from 'vuex'
import HeaderCard from '@/components/common/HeaderCard.vue';

export default {
  components: {
    GoodDataTable, HeaderCard
  },
  data() {
    return {
      headerLabels: [],
      collectCODData: [],
      collectCodAmount: 0,
      dataInfo: {
        fromDate: '',
        toDate: '',
      },
      showAgentSelectionModal: false,
      selectAllOrders: false,
      agentOrdersInfo: [],
      selectedAgentOrders: [],
      agentInfo: null,
      agentsColumns: [
        {
          label: 'Agent',
          field: 'first_name',
          useHtml: true,
          excelHtml: props => `${props.first_name} ${props.last_name}`,
          renderer: props => `<div class="d-flex align-items-center">
                                <img class="agent-image-info" src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" />
                                <span>${props.first_name} ${props.last_name}</span>
                              </div>`,
        },
        {
          label: 'Dispatched',
          field: 'dispatched_order_count',
        },
        {
          label: 'Attempted',
          field: 'attempted_order_count',
        },
        {
          label: 'Delivered',
          field: 'completed_order_count',
        },
        {
          label: 'Delivery Rate',
          field: 'delivery_rate',
          useRenderer: true,
          renderer: props => (props.delivery_rate ? `${props.delivery_rate}%` : '0%'),
        },
        {
          label: 'Collected COD',
          field: 'collected_cod',
        },
        {
          label: 'Deposited COD',
          field: 'deposited_cod',
        },
        {
          label: 'Pending COD',
          field: 'pending_cod',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('payment'),
              text: 'Collect COD',
              action: props => {
                this.collectCOD(props)
              },
            },
          ],
        },
      ],
      collectCodColumns: [
        {
          label: 'Created On',
          field: 'created_time',
        },
        {
          label: 'Order ID',
          field: 'store_order_invoice_id',
        },
        {
          label: 'Total Amount',
          field: 'sub_category_name',
          useResolver: true,
          useHtml: true,
          renderer: props => `${props.currency}${props.total_amount}`,
        },
        {
          label: 'Customer',
          field: 'user',
          useResolver: true,
          useHtml: true,
          renderer: props => !isEmpty(props.user) ? `${props.user.first_name} ${props.user.last_name}` : '',
        },
        // {
        //   label: 'Delivery',
        //   field: 'delivery',
        //   useResolver: true,
        //   useHtml: true,
        //   renderer: props => !isEmpty(props.delivery) ? `${props.delivery.map_address} ${props.delivery.street}` : '',
        // },
      ]
    }
  },
  computed: {
    ordersSelected() {
      return this.agentOrdersInfo.filter(x => x.agentSelected).length
    },
    orderSelectedAmount() {
      return this.agentOrdersInfo
        .filter(x => x.agentSelected && x.total_amount)
        .map(x => parseFloat(x.total_amount))
        .reduce((a, b) => a + b, 0).toFixed(2)
    },
  },
  methods: {
    ...mapActions('store', ['getAgentDashboardStatatics']),
    loadAgentDashboard() {
      const dataPayload = []
      if (this.dataInfo.fromDate) {
        dataPayload.push(`from=${moment(this.dataInfo.fromDate).format('YYYY-MM-DD')}`)
      }
      if (this.dataInfo.toDate) {
        dataPayload.push(`to=${moment(this.dataInfo.toDate).format('YYYY-MM-DD')}`)
      }
      this.$refs.agentsGrid.loadItems(dataPayload.join('&'))
    },
    selectAll($event) {
      this.agentOrdersInfo.forEach(x => {
        // eslint-disable-next-line no-param-reassign
        x.agentSelected = $event
      })
      this.$forceUpdate()
    },
    onItemSelected(data){
      const selectedData = data.selectedRows || []
      let amount = 0
      selectedData.map(i => {
        const totalAmount = parseFloat(i.total_amount)
        if(!isEmpty(totalAmount)){
          amount += totalAmount
        }
      })
      this.collectCodAmount = amount ? amount.toFixed(3) : amount;
    },
    collectCOD(payload) {
      this.agentInfo = payload
      const { agentsPendingCODOrders } = useStoreUi()
      const dataPayload = []
      if (this.dataInfo.fromDate) {
        dataPayload.push(`from=${moment(this.dataInfo.fromDate).format('YYYY-MM-DD')}`)
      }
      if (this.dataInfo.toDate) {
        dataPayload.push(`to=${moment(this.dataInfo.toDate).format('YYYY-MM-DD')}`)
      }
      let dataPayloadStr = dataPayload.join('&')
      if(!isEmpty(dataPayload)){
        dataPayloadStr = `&${dataPayloadStr}`
      }
      showLoader()
      agentsPendingCODOrders(`?statuses=COMPLETED&finance_status=COLLECTED&agent_id=${payload.id}&length=1000${dataPayloadStr}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const agentOrdersInfo = data.responseData.data
            agentOrdersInfo.forEach(x => {
              // eslint-disable-next-line no-param-reassign
              x.agentSelected = false
            })
            this.agentOrdersInfo = agentOrdersInfo
            this.selectAllOrders = false
            this.showAgentSelectionModal = true
            setTimeout(() => {
              this.$refs.collectCod.setLocalData(agentOrdersInfo)
            }, 100);
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    enableDisableSelected(data) {
      const selectedOrders = data.selectedRows || []
      const { depositAgentCOD } = useStoreUi()
      if (isEmpty(selectedOrders)) {
        return
      }
      showLoader()
      depositAgentCOD({
        order_ids: selectedOrders.map(x => x.id).join(','),
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.agentsGrid.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
          this.showAgentSelectionModal = false
        })
        .catch(error => {
          showErrorNotification(this, error)
          this.showAgentSelectionModal = false
        })
    },
    getStatitics(){
      this.headerLabels = []
      const params = {}
      if(!isEmpty(this.dataInfo.fromDate)){
        params.from = moment(this.dataInfo.fromDate).format('YYYY-MM-DD')
      }
      if(!isEmpty(this.dataInfo.toDate)){
        params.to = moment(this.dataInfo.toDate).format('YYYY-MM-DD')
      }
      this.getAgentDashboardStatatics(params).then(r => {
        this.headerLabels = r
      })
    }
  },
  mounted(){
    this.getStatitics()
  }
}
</script>

<style>
.agent-image-info {
  width: 40px;
  height: 40px;
  border-radius: 10%;
  margin-right: 10px;
}
</style>
